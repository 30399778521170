import { Box, Container, Divider, Grid, Link, Typography } from "@mui/material";

export const Footer = () => {
  return (
    <>
      <Divider />
      <Container
        sx={{ bgcolor: "background.paper", p: 6 }}
        component="footer"
        maxWidth="lg"
      >
        <Grid container>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom color="#FF5B1E">
              GROUNDWORK TATTOO
            </Typography>
            <Typography variant="subtitle1">(816) 272-8212</Typography>
            <Typography variant="subtitle1">
              1255 NE Rice rd suite D Lees Summit, MO 64086
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom color="#FF5B1E">
              SOCIALS
            </Typography>
            <Typography variant="subtitle1">
              <Link href="https://www.facebook.com/groundworktattoo/">
                Facebook
              </Link>
            </Typography>
            <Typography variant="subtitle1">
              <Link href="https://www.instagram.com/groundworktattoo/?hl=en">
                Instagram
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Box pt={8}>
          <Typography variant="body2" color="text.secondary" align="center">
            Designed by{" "}
            <Link
              color="inherit"
              href="https://trevorbrixey.com"
              target="_blank"
              rel="noopener"
            >
              Trevor Brixey
            </Link>
          </Typography>
        </Box>
      </Container>
    </>
  );
};
