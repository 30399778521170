import * as React from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ResponsiveAppBar from "./components/ResAppBar";
import "./App.css";
import { Divider, Link } from "@mui/material";
import { Footer } from "./components/Footer";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const cards = [
  {
    name: "Paul",
    ig: "paulmyers_04",
    schedule: "https://groundwork-tattoo.square.site/",
    image: "/paul.jpg",
    imageFilter: { filter: "brightness(0.9)" },
  },
  {
    name: "Luke",
    ig: "lukewhitmiretattoo",
    schedule: "https://tattoosbylukewhitmire.square.site/",
    image: "/luke.jpg",
  },
  {
    name: "Tim",
    ig: "timmmckennyart",
    fb: "TimmMcKennyArt",
    schedule: "mailto:Timmmckenny@gmail.com",
    image: "/tim.jpg",
  },
];

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function Home() {
  const [imageC, setImageC] = React.useState(0);
  return (
    <>
      <ResponsiveAppBar />
      <main>
        <Box
          sx={{
            bgcolor: "transparent",
            pb: 6,
          }}
          className="heroImage"
        >
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <Typography variant="h1">Groundwork Tattoo</Typography>
          </Container>
        </Box>
        <Box>
          <TransformWrapper>
            <TransformComponent>
              <img
                src={"/shop-pano.jpg"}
                alt="panorama of tattoo shop"
                className={"panoImage"}
              />
            </TransformComponent>
          </TransformWrapper>
        </Box>
        <Container sx={{ bgcolor: "transparent", py: 4 }} maxWidth="lg">
          <Typography variant="h3" color="#FF5B1E" sx={{ textAlign: "center" }}>
            About Us
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography fontSize={"1.4rem"}>
            Groundwork tattoo was established in 2018. As artists we strive to
            do clean, solid work that will last a life time. All of our artists
            have 15+ years of experience and with that you can trust you will
            always get a great tattoo and a great experience.
          </Typography>
        </Container>
        <Container sx={{ bgcolor: "transparent", py: 4 }} maxWidth="lg">
          <Typography variant="h3" color="#FF5B1E" sx={{ textAlign: "center" }}>
            Artists
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Grid container spacing={4}>
            {cards.map((card) => (
              <Grid item key={card.name + "card1"} xs={12} sm={6} md={4} lg={4}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography
                      sx={{ textAlign: "center" }}
                      gutterBottom
                      variant="h5"
                      component="h2"
                    >
                      {card.name}
                    </Typography>
                    <CardMedia
                      component="img"
                      image={card.image}
                      alt="random"
                      sx={{
                        height: 420,
                      }}
                      style={card.imageFilter}
                    />
                    <Grid
                      container
                      justifyContent={"center"}
                      flexDirection={"column"}
                    >
                      <Link
                        href={card.schedule}
                        target="_blank"
                        rel="noreferrer"
                        sx={{ textAlign: "center", mt: 1 }}
                      >
                        Schedule
                      </Link>
                      <Link
                        href={"https://instagram.com/" + card.ig}
                        target="_blank"
                        rel="noreferrer"
                        sx={{ textAlign: "center", mt: 1 }}
                      >
                        Instagram: {card.ig}
                      </Link>
                      {card.fb && (
                        <Link
                          href={"https://facebook.com/" + card.fb}
                          target="_blank"
                          rel="noreferrer"
                          sx={{ textAlign: "center", mt: 1 }}
                        >
                          Facebook: {card.fb}
                        </Link>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>

        <Container sx={{ bgcolor: "transparent", py: 4 }} maxWidth="lg">
          <Typography variant="h3" color="#FF5B1E" sx={{ textAlign: "center" }}>
            The Shop
          </Typography>
          <Divider sx={{ my: 1 }} />
          <AutoPlaySwipeableViews
            index={imageC}
            onChangeIndex={(i) => setImageC(i)}
          >
            {[1, 2, 3, 4, 5, 6, 7].map((val) => (
              <img
                key={val}
                src={`/shop/${val}.jpg`}
                alt="Inside of tattoo shop"
                className="shopOfShop"
              />
            ))}
          </AutoPlaySwipeableViews>
        </Container>
        <Container
          id="aftercare"
          sx={{ bgcolor: "transparent", py: 4 }}
          maxWidth="lg"
        >
          <Typography variant="h3" color="#FF5B1E" sx={{ textAlign: "center" }}>
            Aftercare
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Grid container direction="column">
            <Typography variant="h4">
              Our steps for optimal tattoo aftercare.
            </Typography>
            <ul style={{ fontSize: "1.25rem" }}>
              <li>
                Leave the bandage on for at least 1 hour, no more than 4 hours.
              </li>
              <li>
                Remove the bandage and wash thouroughly with antibacterial hand
                soap (ex: Dial or Equate) and warm water.
              </li>
              <li>Pat dry with a paper towel</li>
              <li>
                For the first 3 to 4 days, just wash and keep the tattoo clean 2
                to 3 times a day.
              </li>
              <li>
                When the tattoo starts to feel dry, start to apply a thin layer
                of any nonscented hand lotion (ex: Curel Original)
              </li>
              <li>Showers are fine, no pools, lakes or jacuzzis for 3 week.</li>
            </ul>
          </Grid>
        </Container>
        <Container sx={{ pb: 2 }}>
          <Grid container direction="column">
            <Grid id="contact" container direction="column">
              <Grid
                item
                sx={{
                  pb: 2,
                  textAlign: "center",
                }}
              >
                <Typography variant="h4" mt={2} color="#FF5B1E">
                  Hours
                </Typography>
                <Divider sx={{ my: 1 }} />
              </Grid>
              <Grid
                item
                sx={{
                  pb: 2,
                  textAlign: "center",
                }}
              >
                <Typography sx={{ fontSize: 20 }}>
                  Tuesday - Saturday: <b>12pm - 8pm</b>
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  pb: 2,
                  textAlign: "center",
                }}
              >
                <Typography sx={{ fontSize: 20 }}>
                  Sunday - Monday: <b>closed</b>
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  pb: 2,
                  textAlign: "center",
                }}
              >
                <Typography sx={{ fontSize: 24 }}>
                  Call for appointment or email an artist.
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  textAlign: "center",
                }}
              >
                <Typography sx={{ fontSize: 20 }}>816-272-8212</Typography>
              </Grid>
              <Grid
                item
                sx={{
                  pb: 4,
                  textAlign: "center",
                }}
              >
                <Typography sx={{ fontSize: 20 }}>
                  groundworktattoo@gmail.com
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  pb: 4,
                  textAlign: "center",
                }}
              >
                <Typography sx={{ fontSize: 16 }}>
                  Cash and Credit accepted
                </Typography>
              </Grid>
              <Divider sx={{ my: 1 }} />
              <Grid
                item
                sx={{
                  pb: 2,
                  textAlign: "center",
                }}
              >
                <Typography sx={{ fontSize: 20 }}>
                  Located at 1255 NE Rice Rd D, Lee's Summit, MO 64086
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <img
                  alt="Front of shop"
                  src="/shop-photo.jpg"
                  className="frontOfShop"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Link
                  href={"https://goo.gl/maps/dogm8FSWs2Z5PuMb8"}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Map of shop"
                    src="/map-location.png"
                    className="mapOfShop"
                  />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </main>
      <Footer />
    </>
  );
}
