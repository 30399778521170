import { Box, Button, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ResponsiveAppBar from "./components/ResAppBar";

export default function NotFound() {
  return (
    <>
      <ResponsiveAppBar />
      <main>
        <Box
          sx={{
            bgcolor: "background.paper",
            pb: 6,
          }}
          className="heroImage"
        >
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Page Not Found
            </Typography>
            <Link to="/">
              <Button>Home</Button>
            </Link>
          </Container>
        </Box>
      </main>
    </>
  );
}
